import { environment as defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,
  //we dont need anything else as we only have landings but it uses the default environment = lust
  platform: 'softswiss',
  // isXmasOnly: true,

  rivalName: "slotvibe",
  prettyName: "Slotvibe",
  GA_TRACKING: "G-WVFX65661H",

  metaDescription: "SlotVibe Casino, the best online casino to vibe with free slots. No deposit bonuses, free spins, welcome bonus and free chip slot game await at SlotVibe Casino.",
  logoName: "slotvibe-online-casino-logo.png",
  logoAltText: "SlotVibe Online Casino Logo with Day of the Dead character, golden text, poker chip, Online Slots, Free Spins, Real Money Games"

}

